import { z } from '@invisible/zod'

const schema = z.object({
  baseId: z.string().optional(),
  baseVariableId: z.string().optional(),
  allowAddRows: z.boolean().optional(),
  allowDeleteRows: z.boolean().optional(),
  isReadOnly: z.boolean().optional(),
  rowsLimit: z.number().optional(),
})

type TSchema = z.infer<typeof schema>

export { schema }
export type { TSchema }
